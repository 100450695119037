<template>
  <div class="btns">
    <div class="kf" @click="callCustomerService">
      <img class="kf-icon" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/kf@2x.png" />
      <div class="kf-txt">咨询</div>
    </div>
    <div class="buy-btn" @click="book">预订</div>
  </div>
</template>

<script>
import env from "@/utils/env";

const hlsource = window.huoli && window.huoli.hlsource;

export default {
  props: {
    // transferData: {
    //   type: Array,
    //   default: function () {
    //     return {};
    //   },
    // },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hlsource,
    };
  },
  computed: {
    // item() {
    //   return this.transferData;
    // },
    isNative() {
      return env.isNative;
    },
  },
  methods: {
    book() {
      // let { groups } = this.item;
      this.$emit("book");
    },
    callCustomerService() {
      // window.location.href = "tel:400-898-9999";

      if (["hbgj", "hlsl"].includes(env.hlsource)) {
        window.location.href = "tel:400-898-9999";
      } else if (["kssl"].includes(env.hlsource)) {
        window.location.href = "tel:400-601-7365";
      } else if (env.hlsource === "sinopec"||env.hlsource=='cnpc') {
        this.call("400-601-7365");
      } else {
        this.call("400-898-9999");
      }
    },
    call(phone) {
      window.location.href = `tel:${phone}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.btns {
  display: flex;
  padding: 15 * $px 16 * $px 30 * $px;
  background: #ffffff;
  justify-content: space-between;
  width: 375 * $px;
  box-sizing: border-box;
  .kf {
    width: 80 * $px;
    height: 44 * $px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .kf-icon {
      width: 20 * $px;
    }

    .kf-txt {
      font-size: 13 * $px;
      color: #262626;
    }
  }

  .buy-btn {
    width: 246 * $px;
    height: 44 * $px;
    line-height: 44 * $px;
    background: linear-gradient(90deg, #36a7ff, #1985ff);
    border-radius: 6 * $px;

    font-size: 17 * $px;
    text-align: center;
    color: #ffffff;
  }
}
</style>
