<template>
  <loading :loading="isLoading">
    <div class="tour-group-detail">
      <div class="topFixed" v-if="topSelectShow" :style="{ paddingTop: isNative ? '44px' : '' }">
        <div class="topFixed-wrap"></div>
        <div class="topFixed-contain">
          <img src="@/assets/detail/goBackIcon.png" @click="goBack" />
          <div class="topFixed-contain-item" v-if="productDetail.computerDesc">
            <div :class="topSelect == 0 ? 'topFixed-contain-item-txt select' : 'topFixed-contain-item-txt'" @click="productDetail.computerDesc ? scrollToItem('productDetail') : scrollToItem(0)">产品</div>
          </div>
          <div class="topFixed-contain-item">
            <div :class="topSelect == 1 ? 'topFixed-contain-item-txt select' : 'topFixed-contain-item-txt'" @click="scrollToItem('groupTourTripInfos')">路线</div>
          </div>
          <div class="topFixed-contain-item">
            <div :class="topSelect == 2 ? 'topFixed-contain-item-txt select' : 'topFixed-contain-item-txt'" @click="scrollToItem('costDescription')">费用</div>
          </div>
          <div class="topFixed-contain-item">
            <div :class="topSelect == 3 ? 'topFixed-contain-item-txt select' : 'topFixed-contain-item-txt'" @click="scrollToItem('recommendList')" v-if="!['sinopec', 'boe', 'cnpc'].includes(hlsource)">推荐</div>
          </div>
        </div>
      </div>
      <hl-popup v-model="locationShow" position="bottom" round :style="{ height: '80%' }">
        <div class="departurePopup-top fx-col">
          <div class="departurePopup-top">
            <div class="title">
              <p>选择出发地</p>
              <i class="close" @click="close"></i>
            </div>
          </div>
        </div>
        <div class="departurePopup-contain">
          <div :class="departureSelectIndex == index ? 'hideItem selectHideItem' : 'hideItem'" v-for="(item, index) in productDetail.depInfos" :key="index" @click="departureSelectDetail(item, index)">
            {{ item.destinationName }}
          </div>
        </div>
      </hl-popup>

      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit" class="mescroll">
        <div class="advertisement">
          <div class="slider" v-if="productDetail.images && productDetail.images.length != 0">
            <swipe-comp :ads="productDetail.images" v-if="productDetail.images" :height="242" :axiosObj="photoListObj" :showPhotoList="true" :typeOfBusiness="2" />
            <!-- 返回按钮 -->
            <img class="toBackImg" @click="goBack" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/home-back@2x.png" v-if="!['sinopec', 'wxapp-hbgj', 'boe', 'cnpc'].includes(hlsource)" />
          </div>
        </div>
        <div class="mainTitleWrap"></div>
        <!-- 头部详情 -->
        <div class="mainTitle">
          <div class="title">{{ productDetail.productName }}</div>
          <div class="desc">
            <template v-if="['125026', '126064', '126065', '126068', '126139', '126140'].includes($route.query.productId)">
              <div class="desc-txt" v-for="(item, index) in productDetail.highlights" :key="index">{{ item }}</div>
            </template>
            <template v-else>
              <span v-for="(item, index) in productDetail.highlights" :key="index">{{ item }}</span>
            </template>
          </div>
          <div class="price">
            {{ productDetail.pricePerfixed }}<span>{{ productDetail.price }}</span
            >{{ productDetail.priceSuffix }}
          </div>
        </div>
        <!-- 出发日期 -->
        <div class="dateSelect">
          <div class="dateSelect-title">
            <div class="dateSelect-title-left">出发日期</div>
            <div class="dateSelect-title-right">
              <img src="@/assets/detail/position.png" />
              {{ departure }}出发
              <div class="dateSelect-title-right-select" @click="departureSelect">切换<img src="@/assets/detail/blueArrow.png" /></div>
            </div>
          </div>
          <div class="dateSelect-contain">
            <div :class="index == selectIndexDate ? 'dateSelect-contain-item dateSelect-contain-item-select' : 'dateSelect-contain-item'" v-for="(item, index) in datePriceList" :key="index" @click="datePriceOverHandleConfirm(item, index)">
              <div class="dateSelect-contain-item-top">
                {{ dateTransform(item.saleDate) }}
              </div>
              <div :class="index == selectIndexDate ? 'dateSelect-contain-item-bottom dateSelect-contain-item-bottom-select' : 'dateSelect-contain-item-bottom'">
                {{ item.pricePerfixed }}<span class="price">{{ item.salePrice }}</span
                >{{ item.priceSuffix }}
              </div>
              <img src="@/assets/detail/checkMarkBottom.png" v-if="index == selectIndexDate" />
            </div>
            <div class="dateSelect-contain-wrap"></div>
            <div class="dateSelect-contain-more" @click="showDatePrice">
              <div class="dateSelect-contain-more-box">更多日期</div>
            </div>
          </div>
        </div>
        <!-- 套餐选择 -->
        <div class="setMeals">
          <div class="setMeals-title">套餐选择</div>
          <div class="setMeals-setMealsSelect">
            <div :class="index == selectIndexSetmal ? 'setMeals-setMealsSelect-itemName select' : 'setMeals-setMealsSelect-itemName'" v-for="(item, index) in productDetail.setMeals" :key="index" @click="setMealsSelect(index)">
              {{ item.name }}
              <img src="@/assets/detail/checkmarkIcon.png" v-if="index == selectIndexSetmal" />
            </div>
          </div>
          <div class="setMeals-brief" v-if="!['125026', '126064', '126065', '126068', '126139', '126140'].includes($route.query.productId)">
            <div class="setMeals-brief-top">
              <div class="setMeals-brief-top-item" v-for="(item, index) in productDetail.setMeals[selectIndexSetmal].cardInfo.tripInfo || []" :key="index + 'tripInfo'">
                <div class="setMeals-brief-top-item-title">
                  {{ item.title }}
                </div>
                <div class="setMeals-brief-top-item-desc">{{ item.desc }}</div>
              </div>
            </div>
            <div class="setMeals-brief-bottom">
              <div class="setMeals-brief-bottom-item" v-for="(item, index) in productDetail.setMeals[selectIndexSetmal].cardInfo.tags" :key="index + 'tags'" :style="{ color: 'rgba(' + item.colour + ')' }"><img :src="item.icon" />{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- 定位模块 -->
        <div ref="positionRef" style="width: 1px; height: 1px"></div>
        <!-- 产品详情 -->
        <div class="productDetail" ref="productDetail" v-if="productDetail.computerDesc">
          <div class="title">产品详情</div>
          <div class="desc" v-html="productDetail.computerDesc"></div>
        </div>
        <!-- 行程路线 -->
        <div class="groupTourTripInfos" ref="groupTourTripInfos" v-if="productDetail.setMeals[selectIndexSetmal].groupTourTripInfos">
          <div class="groupTourTripInfos-title">行程路线</div>
          <div class="groupTourTripInfos-contain">
            <div class="groupTourTripInfos-contain-item" v-for="(item, index) in productDetail.setMeals[selectIndexSetmal].groupTourTripInfos" :key="index">
              <div class="groupTourTripInfos-contain-item-title">
                {{ item.title }}
              </div>
              <div class="groupTourTripInfos-contain-item-tip">D{{ item.day }}</div>
              <div class="groupTourTripInfos-contain-item-everyDetail" v-for="(v, i) in item.groupTourProductTripItems" :key="i">
                <div class="groupTourTripInfos-contain-item-everyDetail-title">
                  <img :src="v.icon" />
                  <div class="groupTourTripInfos-contain-item-everyDetail-title-txt">{{ v.poiName }}</div>
                </div>
                <div class="groupTourTripInfos-contain-item-everyDetail-tip" v-if="v.costTip">
                  <div class="groupTourTripInfos-contain-item-everyDetail-tip-txt">
                    {{ v.costTip }}
                  </div>
                  <div class="groupTourTripInfos-contain-item-everyDetail-tip-txt-wrap"></div>
                </div>
                <div class="groupTourTripInfos-contain-item-everyDetail-contain">
                  <div class="groupTourTripInfos-contain-item-everyDetail-contain-playTime" v-if="v.playTime">
                    {{ v.playTime }}
                  </div>
                  <div class="groupTourTripInfos-contain-item-everyDetail-contain-playTime" v-if="v.seatlevel">
                    {{ v.seatlevel }}
                  </div>
                  <div class="groupTourTripInfos-contain-item-everyDetail-contain-playTime" v-if="v.vacancyRateStr">
                    {{ v.vacancyRateStr }}
                  </div>
                  <div class="groupTourTripInfos-contain-item-everyDetail-contain-img" v-if="v.images && v.images.length != 0">
                    <swipe-comp :ads="v.images" :height="175" />
                  </div>
                  <div class="groupTourTripInfos-contain-item-everyDetail-contain-desc" v-html="v.poiDesc"></div>
                  <div class="groupTourTripInfos-contain-item-everyDetail-contain-costTip" v-if="v.costTip">
                    {{ v.costTip }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 费用说明 -->
        <div class="costDescription" ref="costDescription">
          <div class="costDescription-title">费用说明</div>
          <div class="costDescription-constInclude" v-if="productDetail.setMeals[selectIndexSetmal].constInclude">
            <div class="costDescription-constInclude-title">费用包含</div>
            <div class="costDescription-constInclude-desc" v-html="productDetail.setMeals[selectIndexSetmal].constInclude.replaceAll('\n', '<br />')"></div>
          </div>
          <div class="costDescription-constInclude" v-if="productDetail.setMeals[selectIndexSetmal].costExclude">
            <div class="costDescription-constInclude-title">费用不含</div>
            <div class="costDescription-constInclude-desc" v-html="productDetail.setMeals[selectIndexSetmal].costExclude"></div>
          </div>
          <div class="costDescription-constInclude" v-if="productDetail.setMeals[selectIndexSetmal].selfCost">
            <div class="costDescription-constInclude-title">自费项目</div>
            <div class="costDescription-constInclude-desc">
              {{ productDetail.setMeals[selectIndexSetmal].selfCost }}
            </div>
          </div>
        </div>
        <!-- 购买须知 -->
        <div class="bookNotice">
          <div class="bookNotice-title">购买须知</div>
          <div class="bookNotice-tip" v-if="productDetail.setMeals[selectIndexSetmal].bookNotice">报名须知</div>
          <div class="bookNotice-desc" v-if="productDetail.setMeals[selectIndexSetmal].bookNotice" v-html="productDetail.setMeals[selectIndexSetmal].bookNotice.replaceAll('\n', '<br />')"></div>
          <div class="bookNotice-changeDescription">
            <div class="bookNotice-changeDescription-title" v-if="productDetail.refundDesc">退改说明</div>
            <div class="bookNotice-changeDescription-nothing" v-if="productDetail.refundDesc" v-html="productDetail.refundDesc"></div>
            <div class="bookNotice-changeDescription-contain" v-if="productDetail.customRefundRules && productDetail.businessRefundRules && productDetail.customRefundRules.length != 0 && productDetail.businessRefundRules.length != 0">
              <!-- <div class="bookNotice-changeDescription-contain-first">
                <div class="bookNotice-changeDescription-contain-first-title">
                  退改说明
                </div>
                <div class="bookNotice-changeDescription-contain-first-desc">
                  不可退改
                </div>
              </div> -->
              <div class="bookNotice-changeDescription-contain-second" v-if="!['125026', '126064', '126065', '126068', '126139', '126140'].includes($route.query.productId)">
                <div class="bookNotice-changeDescription-contain-second-left">取消政策</div>
                <div class="bookNotice-changeDescription-contain-second-right">
                  <div class="bookNotice-changeDescription-contain-second-right-title">订单生效后，若因买家原因取消订单，买家按以下标准支付违约费用：</div>
                  <div class="bookNotice-changeDescription-contain-second-right-item" v-for="(item, index) in productDetail.customRefundRules" :key="index + item.title">
                    <div class="bookNotice-changeDescription-contain-second-right-item-title">
                      {{ item.title }}
                    </div>
                    <div class="bookNotice-changeDescription-contain-second-right-item-desc">
                      {{ item.desc }}
                    </div>
                  </div>
                  <div class="bookNotice-changeDescription-contain-second-right-title">订单生效后，若因卖家原因取消订单，商家不仅退回全款，同时按照一下标准支付违约费用：</div>
                  <div class="bookNotice-changeDescription-contain-second-right-item" v-for="(item, index) in productDetail.businessRefundRules" :key="index + item.title + '1'">
                    <div class="bookNotice-changeDescription-contain-second-right-item-title">
                      {{ item.title }}
                    </div>
                    <div class="bookNotice-changeDescription-contain-second-right-item-desc">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 推荐列表 -->
        <div class="recommendList" ref="recommendList" v-if="!['sinopec', 'boe', 'cnpc'].includes(hlsource) && !['125026', '126064', '126065', '126068', '126139', '126140'].includes($route.query.productId) && productDetail.recommends && productDetail.recommends.length > 0">
          <div class="recommendList-title">为你推荐</div>
          <div class="recommendList-contain">
            <div class="recommendList-contain-item" v-for="(item, index) in productDetail.recommends" :key="index" @click="jumpToOther(item)">
              <img :src="item.image" />
              <div class="recommendList-contain-item-title">
                {{ item.productName }}
              </div>
              <div class="recommendList-contain-item-price">
                ¥<span>{{ item.price }}</span
                >起
              </div>
            </div>
          </div>
        </div>
        <div class="footFixed">
          <gty-btns-comp @book="handleBook" />
        </div>
      </mescroll-vue>

      <date-price ref="datePriceRef" :query="axiosObj" @confirm="datePriceHandleConfirm" />
    </div>
  </loading>
</template>

<script>
import swipeComp from "../components/swipe";
import service from "@/service";
import get from "@/utils/get";
import moment from "moment";
import gtyBtnsComp from "./btns";
import MescrollVue from "mescroll.js/mescroll.vue";
import nativeApi from "@/utils/nativeApi";
import env from "@/utils/env";
import datePrice from "@/projects/detail/components/date-price";
import loading from "@/components/c-loading.vue";
import * as LocalData from "@/service/localData";

import { Toast } from "huoli-ui";
const TODAY = moment().format("YYYY-MM-DD");
const NEXTDAY = moment().add(1, "d").format("YYYY-MM-DD");
const THEDAYAFTER = moment().add(2, "d").format("YYYY-MM-DD");
export default {
  components: {
    swipeComp,
    gtyBtnsComp,
    MescrollVue,
    datePrice,
    loading,
  },
  data() {
    return {
      isNative: env.isNative,
      hlsource: env.hlsource,
      isLoading: true,
      // selectCity: "",
      //价格日历请求参数
      axiosObj: {
        packageId: "",
        startDate: "",
        endDate: "",
        productId: this.$route.query.productId,
        pid: "215042",
      },
      //选择日期
      selectDate: "",
      //出发地选择
      departureSelectIndex: 0,
      locationShow: false,
      //电梯层现实
      topSelectShow: false,
      //电梯层层数
      topSelect: 0,
      //跳转位置的高度
      itemOffsetTop: {
        productDetail: "",
        groupTourTripInfos: "",
        costDescription: "",
        recommendList: "",
      },
      mescrollDown: {
        use: false,
        // callback: this.downCallback,
      },
      mescrollUp: {
        // callback: this.upCallback,
        // page: {
        //   num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        //   size: 20, //每页数据条数,默认10
        // },
        onScroll: this.onScroll,
      },
      mescroll: null,
      selectIndexDate: null,
      selectIndexSetmal: 0,
      //215042跟团游价格日历
      datePriceList: [],
      //选择的出发城市
      departure: LocalData.getOriginCity().cityName,
      destinationCode: LocalData.getOriginCity().cityCode,
      productDetail: { setMeals: [{ cardInfo: [] }] },
      //是否预订flag
      isBook: false,
    };
  },
  methods: {
    jumpToOther(item) {
      // console.log(item);
      // this.isLoading = true;
      const query = {
        productId: item.productId,
      };
      // const page = { path: "./tour-group-detail", query };
      // // console.log("page", page, item);
      // this.$router.push(page);
      const page = {
        url: "/tour-group-detail",
        name: "tour-group-detail",
        query: query,
        params: {},
      };
      this.$page.push(page);
    },
    //套餐选择
    setMealsSelect(index) {
      this.selectIndexSetmal = index;
      this.axiosObj.packageId = this.productDetail.setMeals[this.selectIndexSetmal].packageId;
      this.getPriceDatePopup(this.axiosObj.packageId, this.axiosObj.productId, this.selectDate, "").then((data) => {
        if (data) {
          let priceInfos = data.priceInfos;
          this.datePriceList = priceInfos.filter((item, index) => index < 6);
          // this.selectIndexDate = 0;
        }
      });
    },
    //点击更多日期
    showDatePrice() {
      this.$refs.datePriceRef.open({
        from: "more-price",
        // date: this.date,
        // productCode: this.productCode,
        // packageCode: this.packageCode,
        // productType: Number(this.productType),
      });
    },
    //时间选择器确认
    datePriceHandleConfirm(item) {
      this.selectDate = item.dateRes[0];
      Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      if (!this.isBook) {
        this.getproductDetailList(this.destinationCode, this.axiosObj.productId, this.selectDate, "").then((data) => {
          // console.log(data);
          // console.log(data);
          this.productDetail = data;
          this.getItemHeight();
          this.getPriceDatePopup(this.axiosObj.packageId, this.axiosObj.productId, this.selectDate, "").then((data) => {
            let priceInfos = data.priceInfos || [];
            this.datePriceList = priceInfos.filter((item, index) => index < 6);
            this.selectIndexDate = 0;
            Toast.clear();
          });
        });
        console.log(this.selectDate);
      } else {
        this.isBook = false;
        if (env.isNative) {
          nativeApi.invoke("createWebView", {
            url: `${process.env.VUE_APP_BASE_URL_PAYMENT}/hangban/vue/hltrip/bookV2?hlsource=${env.hlsource}&analyseSourceEntry=${env.hlsource}&title=${null}&productId=${this.axiosObj.productId}&packageId=${this.axiosObj.packageId}&productType=12&startDate=${this.selectDate}&category=group_tour&cityName=${this.departure}&navbarHidden=0`,
          });
        } else {
          // const a = document.createElement("a");
          // a.target = "_blank";
          // a.href = `${
          //   process.env.VUE_APP_BASE_URL_PAYMENT
          // }/hangban/vue/hltrip/bookV2?hlsource=${
          //   env.hlsource
          // }&title=${null}&productId=${this.axiosObj.productId}&packageId=${
          //   this.axiosObj.packageId
          // }&productType=12&startDate=${
          //   this.selectDate
          // }&category=group_tour&cityName=${this.departure}&navbarHidden=0`;
          // a.click();
          location.href = `${process.env.VUE_APP_BASE_URL_PAYMENT}/hangban/vue/hltrip/bookV2?hlsource=${env.hlsource}&analyseSourceEntry=${env.hlsource}&title=${null}&productId=${this.axiosObj.productId}&packageId=${this.axiosObj.packageId}&productType=12&startDate=${this.selectDate}&category=group_tour&cityName=${this.departure}&navbarHidden=0`;
        }

        // const a = document.createElement("a");
        // a.target = "_blank";

        // a.href = `${process.env.VUE_APP_BASE_URL_PAYMENT}/hangban/vue/hltrip/bookV2?hlsource=${
        //   env.hlsource
        // }&title=${null}&productId=${this.axiosObj.productId}&packageId=${
        //   this.axiosObj.packageId
        // }&productType=12&startDate=${
        //   this.selectDate
        // }&category=group_tour&cityName=${this.departure}&navbarHidden=0`;
        // a.click();
        // location.href =
        Toast.clear();
        console.log("预订", item.dateRes[0]);
      }
    },
    //外层时间选择器
    datePriceOverHandleConfirm(item, index) {
      Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.selectDate = item.saleDate;
      this.getproductDetailList(this.destinationCode, this.axiosObj.productId, this.selectDate, "").then((data) => {
        // console.log(data);
        console.log(data);
        this.productDetail = data;
        this.selectIndexDate = index;
        this.getItemHeight();
        Toast.clear();
      });
      console.log(this.selectDate);
    },
    //出发地选择回掉
    departureSelectDetail(item, index, isShowToast = true) {
      if (isShowToast) {
        Toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
        });
      }
      console.log(item);
      this.departure = item.destinationName;
      this.destinationCode = item.destinationCode;
      this.getproductDetailList(this.destinationCode, this.axiosObj.productId, this.selectDate, "").then((data) => {
        // console.log(data);
        console.log(data);
        this.productDetail = data;
        this.getItemHeight();
      });
      this.departureSelectIndex = index;
      this.locationShow = false;
      Toast.clear();
    },
    //关闭出发地选择
    close() {
      this.locationShow = false;
    },
    //出发地选择
    departureSelect() {
      this.locationShow = true;
    },
    //顶部电梯跳转
    scrollToItem(myRef) {
      if (myRef) {
        this.mescroll.scrollTo(this.itemOffsetTop[myRef] - 50, 300);
        if (myRef == "productDetail") {
          this.topSelect = 0;
        } else if (myRef == "groupTourTripInfos") {
          this.topSelect = 1;
        } else if (myRef == "costDescription") {
          this.topSelect = 2;
        } else if (myRef == "recommendList") {
          this.topSelect = 3;
        }
      }
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    onScroll(mescroll, y) {
      // console.log(y);
      if (y < this.itemOffsetTop.positionRef) {
        this.topSelectShow = false;
      } else if (y >= this.itemOffsetTop.productDetail - 50 && y < this.itemOffsetTop.groupTourTripInfos - 50) {
        this.topSelect = 0;
        this.topSelectShow = true;
        // console.log(this.topSelectShow);
      } else if (y >= this.itemOffsetTop.groupTourTripInfos - 50 && y < this.itemOffsetTop.costDescription - 50) {
        this.topSelect = 1;
        this.topSelectShow = true;
      } else if (y >= this.itemOffsetTop.costDescription - 50 && y < this.itemOffsetTop.recommendList - 100) {
        this.topSelect = 2;
        this.topSelectShow = true;
      } else if (y >= this.itemOffsetTop.recommendList - 100) {
        this.topSelect = 3;
        this.topSelectShow = true;
      }
    },
    handleBook() {
      // console.log("预订");
      if (this.selectDate) {
        console.log("预订");
        //     axiosObj: {
        //   packageId: "",
        //   startDate: "",
        //   endDate: "",
        //   productId: this.$route.query.productId,
        //   pid: "215042",
        // },
        if (env.isNative) {
          nativeApi.invoke("createWebView", {
            url: `${process.env.VUE_APP_BASE_URL_PAYMENT}/hangban/vue/hltrip/bookV2?hlsource=${env.hlsource}&title=${null}&productId=${this.axiosObj.productId}&packageId=${this.axiosObj.packageId}&productType=12&startDate=${this.selectDate}&category=group_tour&cityName=${this.departure}&navbarHidden=0`,
          });
        } else {
          // const a = document.createElement("a");
          // a.target = "_blank";
          location.href = `${process.env.VUE_APP_BASE_URL_PAYMENT}/hangban/vue/hltrip/bookV2?hlsource=${env.hlsource}&title=${null}&productId=${this.axiosObj.productId}&packageId=${this.axiosObj.packageId}&productType=12&startDate=${this.selectDate}&category=group_tour&cityName=${this.departure}&navbarHidden=0`;
          // a.click();
        }

        // location.href = `${process.env.VUE_APP_BASE_URL_PAYMENT}/hangban/vue/hltrip/bookV2?hlsource=${
        //   env.hlsource
        // }&title=${null}&productId=${this.axiosObj.productId}&packageId=${
        //   this.axiosObj.packageId
        // }&productType=12&startDate=${
        //   this.selectDate
        // }&category=group_tour&cityName=${this.departure}`;
        Toast.clear();
      } else {
        this.isBook = true;
        this.showDatePrice();
      }
    },
    getDay(date) {
      const weeks = ["日", "一", "二", "三", "四", "五", "六"];
      return "周" + weeks[moment(date).day()];
    },
    dateTransform(saleDate) {
      if (moment(saleDate).isSame(TODAY)) {
        return `${moment(saleDate).format("MM-DD")}今天`;
      } else if (moment(saleDate).isSame(NEXTDAY)) {
        return `${moment(saleDate).format("MM-DD")}明天`;
      } else if (moment(saleDate).isSame(THEDAYAFTER)) {
        return `${moment(saleDate).format("MM-DD")}后天`;
      } else {
        return moment(saleDate).format("MM-DD") + this.getDay(saleDate);
      }
    },
    goBack() {
      // this.$router.back();
      if (env.hlsource === "sinopec" || env.hlsource == "cnpc") {
        window.location.href = "backtonative.html";
        return;
      } else {
        this.$page.back();
      }
    },
    // 跟团游价格日历215042
    getPriceDatePopup(packageId, productId, startDate, endDate) {
      let obj = {
        packageId: packageId,
        startDate: startDate,
        endDate: endDate,
        productId: productId,
      };
      return service.r215042(obj).then((res) => get(res, "res.bd.data", []));
    },
    //重置高度
    getItemHeight() {
      this.$nextTick(() => {
        this.itemOffsetTop = {
          // productDetail: this.$refs.productDetail.offsetTop,
          // groupTourTripInfos: this.$refs.groupTourTripInfos.offsetTop,
          // costDescription: this.$refs.costDescription.offsetTop,
          // recommendList: this.$refs.recommendList.offsetTop,
        };
        this.itemOffsetTop.positionRef = this.$refs.positionRef.offsetTop;

        if (this.$refs.productDetail) {
          this.itemOffsetTop.productDetail = this.$refs.productDetail.offsetTop;
        }
        if (this.$refs.groupTourTripInfos) {
          this.itemOffsetTop.groupTourTripInfos = this.$refs.groupTourTripInfos.offsetTop;
        }
        if (this.$refs.costDescription) {
          this.itemOffsetTop.costDescription = this.$refs.costDescription.offsetTop;
        }
        if (this.$refs.recommendList) {
          this.itemOffsetTop.recommendList = this.$refs.recommendList.offsetTop;
        }
        // console.log(this.itemOffsetTop);
      });
    },
    //跟团游详情215041
    getproductDetailList(city, productId, date, arrCity) {
      let obj = {
        city: city,
        productId: productId,
        date: date,
        arrCity: arrCity,
      };
      return service.r215041(obj).then((res) => get(res, "res.bd.data", []));
    },
    initData() {
      this.axiosObj = {
        packageId: "",
        startDate: "",
        endDate: "",
        productId: this.$route.query.productId,
        pid: "215042",
      };

      this.isLoading = true;
      Toast.clear();
      this.getproductDetailList(this.destinationCode, this.axiosObj.productId, this.selectDate, "").then((data) => {
        // console.log(data);
        // console.log(data);
        this.productDetail = data;
        // this.departure = this.productDetail.depInfos[0].destinationName;
        this.axiosObj.packageId = this.productDetail.setMeals[this.selectIndexSetmal].packageId;
        if (this.productDetail.depInfos[0].destinationCode == "qg0") {
          this.departureSelectDetail(this.productDetail.depInfos[0], 0, false);
        }
        this.getItemHeight();
        this.isLoading = false;

        if (["wxapp-hbgj"].includes(this.hlsource)) {
          setTimeout(() => {
            env.miniProgramApi.postMessage({
              data: {
                title: this.productDetail.productName,
                imageUrl: this.productDetail.images[0], //显示图片地址
                path: `${process.env.VUE_APP_BASE_URL_PAYMENT}/hltrip/tour-group-detail?productId=${this.axiosObj.productId}&hlsource=wxapp-hbgj`, //页面地址
              },
            });
          }, 600);
        }
        this.getPriceDatePopup(this.axiosObj.packageId, this.axiosObj.productId, this.selectDate, "").then((data) => {
          if (data) {
            let priceInfos = data.priceInfos;
            this.datePriceList = priceInfos.filter((item, index) => index < 6);
            // this.selectIndexDate = 0;
          }
          this.mescroll.scrollTo(0, 0);
        });
      });
    },
  },
  computed: {
    photoListObj() {
      return {
        destinationCode: this.destinationCode,
        productId: this.axiosObj.productId,
        selectDate: this.selectDate,
      };
    },
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "1" });
    }
  },
  created() {
    // this.initData();
  },
  watch: {
    $route: {
      handler: "initData",
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (["wxapp-hbgj"].includes(this.hlsource)) {
      setTimeout(() => {
        env.miniProgramApi.postMessage({
          data: {},
        });
      }, 600);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.departurePopup-top {
  .title {
    position: relative;
    padding: 14 * $px 0;
    p {
      margin: 0;
      text-align: center;
      font-size: 18 * $px;
      font-weight: 500;
      color: #262626;
      line-height: 25 * $px;
    }
    i {
      position: absolute;
      top: 19 * $px;
      left: 16 * $px;
      display: inline-block;
      width: 14 * $px;
      height: 14 * $px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      margin-right: 8 * $px;
      transform: translate3d(0, 0px, 0);
      background-image: url("https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/close-1@3x.png");
    }
  }
}
.departurePopup-contain {
  padding-left: 16 * $px;
  padding-top: 16 * $px;
  padding-bottom: 16 * $px;
  // padding: 16 * $px 16 * $px 0;
  // width: 343 * $px;
  display: flex;
  justify-content: flex-start;
  align-items: normal;
  flex-wrap: wrap;
  .hideItem {
    // flex-shrink: 0;
    width: 80 * $px;
    height: 36 * $px;
    margin-bottom: 8 * $px;
    background: #f7f9fc;
    border-radius: 4 * $px;
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #262626;
    line-height: 36 * $px;
    margin-right: 8 * $px;
  }
  .selectHideItem {
    background: #0078ff;
    color: #ffffff;
  }
}

.topFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 375 * $px;
  z-index: 10;
  background-color: #ffffff;
  &-wrap {
    width: 375 * $px;
    // height: 44 * $px;
  }
  &-contain {
    display: flex;
    padding-left: 17 * $px;
    width: 375 * $px;
    height: 44 * $px;
    box-sizing: border-box;
    align-items: center;
    img {
      width: 9 * $px;
      height: 18 * $px;
      margin-right: 16 * $px;
    }
    &-item {
      width: 66 * $px;
      display: flex;
      // align-items: center;
      justify-content: center;
      &-txt {
        height: 44 * $px;
        box-sizing: border-box;
        font-size: 15 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #262626;
        text-align: center;
        line-height: 44 * $px;
      }
      .select {
        border-bottom: solid 3 * $px #0078ff;
        color: #0078ff;
      }
    }
  }
}
.mescroll {
  position: fixed;
  top: 0;
  // top: calc(44px + env(safe-area-inset-top));
  bottom: 0;
  height: auto;
  background: #f2f4f7;
  // padding-bottom: 10 * $px;
  padding-bottom: 49 * $px;
}
.footFixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 127;
}
.tour-group-detail {
  background: #f2f4f7;
  position: relative;
}
.slider {
  position: relative;
  width: 375 * $px;
  height: 242 * $px;
  //   margin-bottom: 121 * $px;
  .toBackImg {
    width: 28 * $px;
    height: 28 * $px;
    position: absolute;
    top: 52 * $px;
    left: 12 * $px;
    z-index: 2;
  }
}
.mainTitleWrap {
  position: absolute;
  left: 8 * $px;
  top: 230 * $px;
  right: 8 * $px;
  // width: 359 * $px;
  height: 15 * $px;
  background: #ffffff;
  border-top-left-radius: 12 * $px;
  border-top-right-radius: 12 * $px;
}
.mainTitle {
  // width: 359 * $px;
  background: #ffffff;
  border-bottom-left-radius: 12 * $px;
  border-bottom-right-radius: 12 * $px;
  box-sizing: border-box;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  padding: 0 12 * $px 12 * $px 12 * $px;
  margin-bottom: 12 * $px;
  .title {
    width: 335 * $px;
    font-size: 17 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #262626;
    line-height: 24 * $px;
    margin-bottom: 8 * $px;
  }
  .desc {
    width: 335 * $px;
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(52, 56, 75, 0.75);
    line-height: 18 * $px;
    .desc-txt {
      margin-bottom: 3 * $px;
    }
  }
  .price {
    font-size: 12 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    margin-top: 16 * $px;
    text-align: right;
    // text-align: left;
    color: #ff6814;
    span {
      font-size: 18 * $px;
    }
    // line-height: 17px;
  }
}

::-webkit-scrollbar {
  display: none;
}
.dateSelect {
  position: relative;
  // width: 359 * $px;
  height: 113 * $px;
  background: #ffffff;
  border-radius: 12 * $px;
  padding: 12 * $px;
  box-sizing: border-box;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  margin-bottom: 8 * $px;
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12 * $px;
    &-left {
      font-size: 17 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #262626;
      //   line-height: 24px;
    }
    &-right {
      display: flex;
      align-items: center;
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #262626;
      img {
        width: 12 * $px;
        height: 14 * $px;
        margin-right: 5 * $px;
      }
      &-select {
        margin-left: 12 * $px;
        font-size: 12 * $px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 400;
        color: #0078ff;
        display: flex;
        align-items: center;
        // line-height: 18px;
        img {
          margin-left: 6 * $px;
          width: 4 * $px;
          height: 7 * $px;
        }
      }
    }
  }
  &-contain {
    display: flex;
    width: 335 * $px;
    flex-wrap: nowrap;
    // width: 100%;
    // height: 53 * $px;
    overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    // white-space: nowrap;
    &-item {
      width: 73 * $px;
      height: 53 * $px;
      background: #f8f9fb;
      border-radius: 6 * $px;
      margin-right: 6 * $px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: relative;
      img {
        width: 20 * $px;
        height: 20 * $px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      &-top {
        font-size: 12 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        margin-bottom: 4 * $px;
        // text-align: center;
        color: #262626;
        // line-height: 17px;
      }
      &-bottom {
        font-size: 10 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        // text-align: center;
        color: rgba(52, 56, 75, 0.75);
        .price {
          font-size: 14 * $px;
        }
        &-select {
          color: #ff6814;
        }
        // line-height: 14px;
      }
      &-select {
        background: rgba(0, 120, 255, 0.08);
        color: #0078ff;
      }
    }
    &-wrap {
      flex-shrink: 0;
      width: 48 * $px;
      height: 53 * $px;
    }
    &-more {
      position: absolute;
      top: 48 * $px;
      right: 12 * $px;
      padding-left: 8 * $px;
      background-color: #ffffff;
      &-box {
        width: 40 * $px;
        height: 53 * $px;
        border: 1 * $px solid rgba(52, 56, 75, 0.1);
        border-radius: 7 * $px;
        font-size: 11 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: rgba(52, 56, 75, 0.75);
        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding: 7 * $px 7 * $px 6 * $px 7 * $px;
        box-sizing: border-box;
        // line-height: 40 * $px;
        flex-wrap: wrap;
      }
    }
  }
}
.setMeals {
  // width: 359 * $px;
  // height: 312 * $px;
  opacity: 1;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  padding: 12 * $px;
  box-sizing: border-box;
  margin-bottom: 8 * $px;
  &-title {
    font-size: 17 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    // text-align: left;
    color: #262626;
    // line-height: 24px;
    margin-bottom: 13 * $px;
  }
  &-setMealsSelect {
    margin-bottom: 8 * $px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    &-itemName {
      position: relative;
      margin-right: 15 * $px;
      flex-shrink: 0;
      width: 264 * $px;
      opacity: 1;
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      padding: 12 * $px;
      box-sizing: border-box;
      color: #34384b;
      background: #f8f9fb;
      img {
        width: 20 * $px;
        height: 20 * $px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .select {
      color: #0078ff;
      background: rgba(0, 120, 255, 0.08);
    }
  }
  &-brief {
    &-top {
      width: 335 * $px;
      min-height: 86 * $px;
      // height: 141 * $px;
      background-image: url("../../../assets/detail/briefBg.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: transparent;
      background-position: top right;
      object-fit: cover;
      padding: 15 * $px 0 8 * $px 12 * $px;
      box-sizing: border-box;
      // background-attachment: cover;
      &-item {
        display: flex;
        margin-bottom: 2 * $px;
        &-title {
          width: 50 * $px;
          font-size: 13 * $px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(52, 56, 75, 0.75);
          line-height: 22 * $px;
        }
        &-desc {
          opacity: 1;
          font-size: 13 * $px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #262626;
          line-height: 22 * $px;
        }
      }
    }
    &-bottom {
      width: 335 * $px;
      height: 34 * $px;
      background: #dcecff;
      padding: 8 * $px 0 9 * $px 12 * $px;
      box-sizing: border-box;
      display: flex;
      &-item {
        flex-shrink: 0;
        font-size: 12 * $px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #0078ff;
        margin-right: 20 * $px;
        display: flex;
        align-items: center;
        img {
          width: 12 * $px;
          height: 12 * $px;
          margin-right: 6 * $px;
        }
      }
    }
  }
}
.productDetail {
  margin-bottom: 8 * $px;
  // width: 359 * $px;
  // height: 209 * $px;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  padding: 12 * $px;
  box-sizing: border-box;
  overflow-x: hidden;
  .title {
    font-size: 17px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
    margin-bottom: 13 * $px;
  }
  .desc {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #262626;
    line-height: 22px;
  }
}
.groupTourTripInfos {
  // width: 359 * $px;
  // height: 1508px;
  // opacity: 1;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  padding: 12 * $px 0;
  box-sizing: border-box;
  margin-bottom: 8 * $px;
  &-title {
    font-size: 17 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
    // line-height: 24px;
    margin-left: 12 * $px;
    margin-bottom: 12 * $px;
  }

  &-contain {
    width: 359 * $px;
    &-item {
      position: relative;
      &-title {
        font-size: 17px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        // text-align: left;
        color: #262626;
        line-height: 24 * $px;
        margin-bottom: 16 * $px;
        margin-left: 39 * $px;
      }
      &-tip {
        width: 31 * $px;
        height: 24 * $px;
        // opacity: 1;
        background: #0078ff;
        border-radius: 2px 100px 100px 2px;
        font-size: 13 * $px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 24 * $px;
        position: absolute;
        left: 0;
        top: 0;
      }
      &-everyDetail {
        &-title {
          display: flex;
          margin-left: 8 * $px;
          font-size: 16 * $px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #262626;
          &-txt {
            width: 312 * $px;
          }
          img {
            margin-right: 12 * $px;
            width: 19 * $px;
            height: 19 * $px;
            padding-top: 2 * $px;
          }
        }
        &-tip {
          // width: 375 * $px;
          margin-top: 4 * $px;
          margin-left: 39 * $px;
          // margin-bottom: 8 * $px;
          display: flex;
          &-txt {
            border: 1 * $px solid rgba(52, 56, 75, 0.15);
            border-radius: 5 * $px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: rgba(52, 56, 75, 0.5);
            // padding: 7 * $px 7 * $px 4 * $px 7 * $px;
            padding: 1 * $px 4 * $px;
            font-size: 10 * $px;
          }
        }
        &-contain {
          width: 312 * $px;
          margin-top: 8 * $px;
          margin-left: 16 * $px;
          margin-bottom: 8 * $px;
          padding-bottom: 8 * $px;
          border-left: solid 2px #f2f4f7;
          padding-left: 21 * $px;
          padding-right: 8 * $px;
          &-img {
            margin-bottom: 12 * $px;
            margin-top: 8 * $px;
          }
          &-desc {
            margin-bottom: 8 * $px;
          }
          &-playTime {
            display: flex;
            align-items: center;
            font-size: 13 * $px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            // text-align: left;
            color: rgba(52, 56, 75, 0.75);
            // line-height: 18px;
            img {
              margin-right: 4 * $px;
              width: 16 * $px;
              height: 16 * $px;
            }
          }
          // &-costTip {
          // }
        }
      }
    }
  }
}
.costDescription {
  margin-bottom: 8 * $px;
  // width: 359 * $px;
  // height: 178px;
  // opacity: 1;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  padding: 12 * $px;
  padding-bottom: 8 * $px;
  box-sizing: border-box;
  &-title {
    font-size: 17 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    // text-align: left;
    color: #262626;
    // line-height: 24px;
    margin-bottom: 12 * $px;
  }
  &-constInclude {
    display: flex;
    margin-bottom: 4 * $px;
    &-title {
      width: 72 * $px;
      // height: 22px;
      // opacity: 1;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      // text-align: left;
      color: rgba(52, 56, 75, 0.75);
      // line-height: 22px;
    }
    &-desc {
      width: 263 * $px;
      // height: 44px;
      // opacity: 1;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      // text-align: left;
      color: #262626;
      line-height: 22 * $px;
    }
  }
}
.bookNotice {
  // width: 359 * $px;
  // height: 636px;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  padding: 12 * $px;
  box-sizing: border-box;
  margin-bottom: 8 * $px;
  &-title {
    font-size: 17 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #262626;
    margin-bottom: 12 * $px;
    // line-height: 24px;
  }
  &-tip {
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    // text-align: left;
    color: #262626;
    margin-bottom: 8 * $px;
    // line-height: 18px;
  }
  &-desc {
    width: 335 * $px;
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    // text-align: left;
    color: rgba(52, 56, 75, 0.75);
    line-height: 18 * $px;
    margin-bottom: 16 * $px;
  }
  &-changeDescription {
    &-title {
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      // text-align: left;
      color: #262626;
      margin-bottom: 8 * $px;
    }
    &-contain {
      &-first {
        display: flex;
        margin-bottom: 2 * $px;
        &-title {
          width: 90 * $px;
          height: 38 * $px;
          background: #edf1f7;
          border-radius: 8 * $px 0px 0px 0px;
          font-size: 13 * $px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #262626;
          line-height: 38 * $px;
          margin-right: 1 * $px;
        }
        &-desc {
          width: 243 * $px;
          height: 38 * $px;
          background: #f7f9fc;
          border-radius: 0px 8 * $px 0px 0px;
          padding: 10 * $px 0;
          padding-left: 12 * $px;
          font-size: 13 * $px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          // text-align: left;
          color: #262626;
          box-sizing: border-box;
          // line-height: 18px;
        }
      }
      &-second {
        display: flex;
        &-left {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8 * $px 0px 0px 8 * $px;
          width: 90 * $px;
          flex-shrink: 0;
          background: #edf1f7;
          // border-radius: 0px 0px 0px 8px;
        }
        &-right {
          width: 243 * $px;
          opacity: 1;
          background: #f7f9fc;
          border-radius: 0px 8 * $px 8 * $px 0px;
          padding: 0 13 * $px 8 * $px 13 * $px;
          box-sizing: border-box;
          &-title {
            font-size: 13 * $px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            // text-align: left;
            color: #262626;
            line-height: 18 * $px;
            padding-bottom: 8 * $px;
            padding-top: 10 * $px;
            border-bottom: solid 1 * $px #d8d8d8;
          }
          &-item {
            display: flex;
            border-bottom: solid 1 * $px #d8d8d8;

            &-title {
              width: 86 * $px;
              box-sizing: border-box;
              padding-top: 10 * $px;
              padding-bottom: 9 * $px;
              font-size: 13 * $px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              // text-align: left;
              color: #262626;
              // line-height: 18px;
              border-right: solid 1 * $px #d8d8d8;
            }
            &-desc {
              // width: 86 * $px;
              box-sizing: border-box;
              padding-top: 10 * $px;
              padding-bottom: 9 * $px;
              padding-left: 32 * $px;
              font-size: 13 * $px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              // text-align: left;
              color: #262626;
              // line-height: 18px;
              // border-right: solid 1 * $px #d8d8d8;
            }
          }
        }
      }
    }
    &-nothing {
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(52, 56, 75, 0.75);
      margin-bottom: 8 * $px;
    }
  }
}
.recommendList {
  // width: 359 * $px;
  margin-left: 8 * $px;
  margin-right: 8 * $px;
  // height: 410px;
  background: #ffffff;
  border-radius: 12 * $px;
  padding: 12 * $px;
  box-sizing: border-box;
  margin-bottom: 8 * $px;
  &-title {
    font-size: 17 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
    margin-bottom: 12 * $px;
  }
  &-contain {
    width: 335 * $px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    &-item {
      position: relative;
      margin-bottom: 12 * $px;
      padding-bottom: 31 * $px;
      img {
        width: 162 * $px;
        height: 110 * $px;
        border-radius: 12 * $px;
        margin-bottom: 8 * $px;
        // overflow: hidden;
      }
      &-title {
        width: 162 * $px;
        // height: 20px;
        // opacity: 1;
        font-size: 14 * $px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        // text-align: left;
        color: #262626;
        // line-height: 20px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
      &-price {
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 1;
        font-size: 10 * $px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        // text-align: left;
        color: #ff6814;
        // line-height: 14px;
        span {
          font-size: 16 * $px;
        }
      }
    }
  }
}
</style>
